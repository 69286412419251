import http from "utils/request";
import payhttp from "utils/payrequest";
// 上传图片
export const Ossupload = (params = {}) => {
  return payhttp.post("/api/fileupload", params);
};
export const GetAliimageUrl = (params = {}) => {
  return payhttp.post("/api/member/get_aliimage_url", params);
};

// 绑定耳畔账号
export const SetWarehouse = (params = {}) => {
  return http.post("/api/common/set_warehouse", params);
};
export const GetWarehouseZyt = (params = {}) => {
  return http.get("/api/member/get_warehouse_zyt", { params });
};

export const VerifyWarehouse = (params = {}) => {
  return http.post("/api/common/verify_warehouse", params);
};
export const SendWarehouseSms = (params = {}) => {
  return http.post("/api/common/send_warehouse_sms", params);
};

// 事件埋点
export const AddEvent = (params = {}) => {
  return http.post("/api/member/add_event", {
    ...params,
    even_type: 4,
    channel: 1,
    visitor_id: window.visitorId,
  });
};

// 图片编辑 ---- start ----
// 获取一次性操作码
export const GetYktCode = (params = {}) => {
  return http.get("/api/member/get_ykt_code", { params });
};

// 获取当前用户能否使用功能
export const GetMemberAiStatus = (params = {}) => {
  return http.get("/api/member/get_member_ai_status", { params });
};

// 获取当前用户能否使用功能
export const AddAiRecord = (params = {}) => {
  return http.post("/api/member/add_ai_record", params);
};

// 图片编辑 ---- end ----
