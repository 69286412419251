import { useEffect, useState, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Routers from "config/route";
import { RightOutlined, CloseOutlined } from "@ant-design/icons";

// css
import "./assets/css/common.less";

// util
import store from "store";
import Storage from "utils/storage";
import { delay } from "utils";

// api
import { setting, daylogin } from "api/login";
import { AddDataOverview } from "api/home";
import { GetArticleList } from "api/areicle";

// module
import Home from "pages/new_home";
import Merchant from "pages/merchant/merchant";
import RightAffix from "pages/components/RightAffix";
import H5 from "pages/h5/h5";

import "core-js/es";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "@babel/polyfill";

function App() {
  const [pathName, setPathName] = useState("");

  // 监听路由变化 ---- start ----
  /**
   * 重写history的pushState和replaceState
   * @param action pushState|replaceState
   * @return {function(): *}
   */
  function wrapState(action) {
    let raw = window.history[action];
    return function () {
      let wrapper = raw.apply(this, arguments);
      let e = new Event(action);
      e.stateInfo = { ...arguments };
      window.dispatchEvent(e);
      return wrapper;
    };
  }

  //修改原始定义
  window.history.pushState = wrapState("pushState");
  window.history.replaceState = wrapState("replaceState");
  window.addEventListener("pushState", function (e) {
    setPathName(e?.stateInfo?.[2]);
  });
  window.addEventListener("replaceState", function (e) {
    setPathName(e?.stateInfo?.[2]);
  });
  window.addEventListener("popstate", function () {
    setPathName(window?.location?.pathname);
  });
  // 监听路由变化 ---- end ----

  // 配置项初始化
  useEffect(() => {
    // 重置页面滚动高度
    window.history.scrollRestoration = "manual";

    const getData = async () => {
      const json = Storage?.getUser?.() || '{}';
      const form = JSON.parse(json);
      const time = Storage?.getTIME?.();
      if (form?.token && time) {
        if (time < +new Date() - 86400000) {
          const res = await daylogin({
            member_id: +JSON.parse(Storage.getUser()).member_id,
          });
          if (!res) return;
          if (res.ResponseStatus) return;
          Storage.setTIME(+new Date());
        }
      }

      const set_join = Storage.getSetting();
      const set_form = JSON.parse(set_join);
      if (set_form?.time > +new Date() - 1000 * 1) return;
      await delay(1000);
      const res = await setting({});
      const new_form = { ...(res?.datas || {}), time: +new Date() };
      const new_json = JSON.stringify(new_form);
      Storage.setSetting(new_json);
    };
    getData();
  }, []);

  // 检测是否PC端访问
  const IsPC = () => {
    const userAgentInfo = navigator.userAgent;
    const Agents = [
      "Android",
      "iPhone",
      "iphone os",
      "midp",
      "rv:1.2.3.4",
      "ucweb",
      "SymbianOS",
      "windows ce",
      "Windows Phone",
      "windows mobile",
      "iPad",
      "iPod",
      "Vivo",
      "vivo",
      "Nokia",
    ];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  };

  // 页面数据监听
  useEffect(() => {
    window.StartTime = +new Date();
    let fn = () => {
      if (document.visibilityState === "visible") {
        setTimeout(() => {
          window.StartTime = +new Date();
        }, 60);
      } else {
        window.EndTime = +new Date();
        AddDataOverview({
          visitor_id: window.visitorId,
          start_time: +(window.StartTime / 1000)?.toFixed(0),
          end_time: +(window.EndTime / 1000)?.toFixed(0),
          duration: +((window.EndTime - window.StartTime) / 1000)?.toFixed(0),
          page_name: window.location?.pathname?.split?.("/")?.[1],
          searchs: window.location?.pathname?.split?.("/")?.[2],
          channel: 1,
        });
      }
    };
    document.addEventListener("visibilitychange", fn);
    window.onbeforeunload = fn;
    return () => {
      document.removeEventListener("visibilitychange", fn);
    };
  }, []);

  // 平台公告
  const [articleTopVisible, setArticleTopVisible] = useState(false);
  const [articleTop, setArticleTop] = useState([]);
  useEffect(() => {
    const day_time = 7 * 24 * 3600 * 1000;
    const getArticleTopList = async () => {
      const res = await GetArticleList({ count: 1, class_name: "平台公告" });
      if(!res)return
      if(!res?.datas)return
      const res_arr = res?.datas || [];
      setArticleTop([...res_arr]);
      const arr = store.get("ArtileId") || [];
      const index = arr.findIndex((k) => k.id === res_arr?.[0]?.id);
      if (index === -1) return setArticleTopVisible(true);
      const form = arr[index] || {};
      const ArtileId = form?.id;
      if (res_arr?.[0]?.id !== ArtileId) {
        setArticleTopVisible(true);
      } else {
        const time = +new Date();
        if (time > (form?.time || 0) + day_time && !form?.is_none) {
          setArticleTopVisible(true);
        }
      }
    };
    getArticleTopList();
  }, []);

  const onCloseArticle = () => {
    const form = {
      id: articleTop?.[0]?.id,
      time: +new Date(),
    };
    const arr = store.get("ArtileId") || [];
    const new_arr = arr?.filter?.((k) => k.id !== form.id);
    new_arr.unshift(form);
    store.set("ArtileId", new_arr);
    setArticleTopVisible(false);
  };

useEffect(()=>{
  if(!IsPC() && !global.private){
    console.log(window.location);
    const pathname = window.location.pathname
    const arr = pathname?.split?.('/')
    const key = arr[1]
    const id = arr[2]
    const h5Url = {
      'merchandise': `https://h5.fangzhifu.com/pages/Mainpackage/classification/listInfo?goods_id=`+id,
      'merchant': `https://h5.fangzhifu.com/pages/Mainpackage/store/index?store_id=`+id
    }
    
    window.location.href = h5Url[key] || 'https://h5.fangzhifu.com'
  }
}, [])

  return (
    <div className="App">
      <header className="App-header">
        <Suspense
          fallback={
            <div className="textC" style={{ paddingTop: 200 }}>
              页面加载中...
            </div>
          }
        >
          <BrowserRouter>
            <Switch>
              <Route
                path="/"
                exact
                component={IsPC() ? (global.private ? Merchant : Home) : H5}
              />
              {Routers.map((item, index) => (
                <Route
                  key={index}
                  path={item.path}
                  exact
                  render={(props) =>
                    !item.auth ? (
                      <Route
                        path={item.path}
                        component={IsPC() ? item.component : H5}
                      />
                    ) : JSON.parse(Storage.getUser()) ? (
                      <Route
                        path={item.path}
                        component={IsPC() ? item.component : H5}
                      />
                    ) : (
                      <Redirect
                        to={{
                          pathname: IsPC() ? "/login" : H5,
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                />
              ))}
              <Redirect to="/" />
            </Switch>
          </BrowserRouter>
        </Suspense>
      </header>

      {!["/login"]?.includes?.(pathName) && !global.private ? <RightAffix /> : null}

      {/* 平台公告 */}
      {articleTopVisible ? (
        <div className="article-fix-modal">
          <div
            className="modal-close"
            onClick={() => {
              onCloseArticle();
            }}
          >
            <CloseOutlined />
          </div>

          <div className="modal-title flex-b flex-center">
            <span className="title">平台公告</span>
            <span
              className="more pointer"
              onClick={() => {
                onCloseArticle();
                window.open(`/article?ac_id=12`);
              }}
            >
              <span>更多</span>
              <RightOutlined className="fz10" />
              <RightOutlined className="fz10" />
            </span>
          </div>
          <div className="artile-main">
            <span className="article-title">{articleTop?.[0]?.title}</span>
            <div className="article-introduction owt4">
              {articleTop?.[0]?.introduction}
            </div>

            <div className="flex-centerY">
              <div
                className="left"
                onClick={() => {
                  const form = {
                    id: articleTop?.[0]?.id,
                    time: +new Date(),
                    is_none: true,
                  };
                  const arr = store.get("ArtileId") || [];
                  const new_arr = arr?.filter?.((k) => k.id !== form.id);
                  new_arr.unshift(form);
                  store.set("ArtileId", new_arr);
                  setArticleTopVisible(false);
                }}
              >
                不再提示
              </div>
              <div
                className="left right"
                onClick={() => {
                  onCloseArticle();
                  window.open(`/article?id=${articleTop?.[0].id}`);
                }}
              >
                查看详情
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default App;
