import { useState } from "react";
import { Image } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  EffectFade,
} from "swiper/core";

// css
import "./index.less";
import "swiper/swiper.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);

const SmallBanner = (props) => {
  const { data } = props;
  const [swiper, setSwiper] = useState(2500);

  const onSlideChangeTransitionEnd = () => {
    if (swiper && swiper.realIndex > -1) {
      if (swiper.realIndex) {
        swiper.slideToLoop(-4, 0);
        swiper.autoplay.start();
      } else {
        swiper.slideToLoop(0, 0);
        swiper.autoplay.start();
      }
    }
  };
  const onMouseEnter = () => {
    swiper.autoplay.stop();
  };
  const onMouseLeave = () => {
    swiper.autoplay.start();
  };

  return (
    <div className="new_home-small-banner">
      <Swiper
        slidesPerView={5}
        slidesPerGroup={5}
        spaceBetween={8}
        loop
        initialSlide={4}
        autoplay={{ delay: 5000 }}
        pagination={false}
        navigation={true}
        className="mySwiper"
        onSwiper={setSwiper}
        onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
      >
        {data?.map?.((k, i) => (
          <SwiperSlide
            key={i}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={() => {
              window.open(k.template_url);
            }}
            className="pointer"
          >
            <Image
              className="image"
              preview={false}
              src={`${k.template_img}?x-oss-process=image/resize,m_lfit,h_400,w_400`}
              alt={k.template_name}
              fallback="https://osss.fangzhifu.com/zyt.jpeg"
            />
            <div className="goods-info">
              <div className="store-name owt">{k.store_name}</div>
              <div className="template_name owt">{k.template_name}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default SmallBanner;
